// eslint-disable-next-line no-redeclare,no-unused-vars
function getObjectValue(object, subs, empty) {
  if (!object) return empty;
  for (var i = 0; i < subs.length; i++) {
    if (object[subs[i]]) object = object[subs[i]];
    else if (isset(empty)) return empty;
    else return object[subs[i]];
  }
  return object;
}
